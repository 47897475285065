import React from "react"
import { css } from "@emotion/core"
import snarkdown from "snarkdown"

export default ({ raw, fontSize="1.05rem" }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: snarkdown(raw).replace(/<img src="/g, `<img src="/`) // add slash to beginning of image paths because Netlify CMS provides image paths without slash at the beginning
      }}
      css={css`
        color: var(--text-300);
        line-height: 1.6;
        font-size: ${fontSize};

        * {
          color: var(--text-300);
          line-height: 1.6;
        }

        br {
          display: block;
          content: "";
          margin-top: 1rem;
        }

        h1 {
          font-size: 1.7rem;
          margin-top: 3rem;
          color: var(--text-500);
          letter-spacing: -.03rem;
        }

        h2 {
          font-size: 1.4rem;
        }

        img {
          max-width: 100%;
          border-radius: 8px;
        }

        blockquote {
          border-left: 4px solid var(--accent-100);
          margin: 32px 0;
          padding: 16px 0;
          padding-left: 32px;
        }

        .code {
          overflow: scroll;
          max-width: 1024px;
          padding-bottom: 16px;
        }

        hr {
          margin: 32px 0;
          border: 1px solid var(--text-100);
        }
      `}
    />
  )
}
