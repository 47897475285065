import React from "react"
import { css } from "@emotion/core"
import snarkdown from "snarkdown"

import Template from "../components/Template"
import Markdown from "../components/Markdown"

export default ({ pageContext }) => {
  const { post } = pageContext

  return (
    <Template
      title={post.title}
      description={snarkdown(post.body).replace(/<(.*?)>/g, " ").replace(/ +/g, " ")}
    >
      <div
        css={css`
          margin-bottom: 32px;
          padding-bottom: 32px;
          border-bottom: 2px solid var(--accent-100);
        `}
      >
      
        <h1
          css={css`
            margin: 0;
            margin-bottom: 16px;
          `}
        >
          {post.title}
        </h1>

        <time
          itemProp="datePublished"
          dateTime={post.published_date}
          css={css`
            color: var(--text-300);
          `}
        >
          {new Date(post.published_date).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric"
          })}
        </time>

      </div>

      <Markdown raw={post.body} />
    </Template>
  )
}